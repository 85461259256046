import React from 'react'
import { MainLayout } from '../../../../layout'
import { AnnoyedCuate } from '../../../../assets/illustration'

export const FAQPage = () => {
  return (
    <MainLayout>
        <div className='bt-page'>
          <div className={"kbn-user-error"}>
              <div className={"kbn-user-error__container"}>
                <AnnoyedCuate/>
                <h2>En developpement </h2>
              </div>
          </div>
        </div>
    </MainLayout>
  )
}
