import React from 'react'
import { MainLayout } from '../../../../layout'

export const ProductsPage = () => {
  return (
    <MainLayout>
      <div className='bt-page'>
        
      </div>
    </MainLayout>
  )
}
