import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { links } from '../../../../../routes';
import { resetPassword } from '../../../../../app/feature/auth/auth.slice';
import { Button } from '../../../../components/src/button';
import { AuthLayout } from '../../../../layout';
import { Textfield } from '../../../../components/src/field';
import { AlertModule } from '../../../../components/src/alert';
import images from '../../../../assets/images';

export const RecoverPage = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token_url = searchParams.get('token')
    const {image2} = images
    const [data, setData] = useState<any>({
        confirmPassword: "", newPassword: ""
    })

    let navigate = useNavigate()
    
    const dispatch = useAppDispatch();
    const { isPasswordSuccess, isPasswordLoading, isPasswordError, message } = useAppSelector((state) => state.auth);
    const { token } = useContext(UserAuthContext); 

    useEffect(() => {
        if(!token_url){
            navigate(links.error)
        }
    }, [token_url, navigate])

    useEffect(() => {
        if(token){
            navigate(links.home)
        }
    }, [token, navigate]);

    const [error, setError] = useState({
        field: "",
        message: ""
    })
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(!data.newPassword){
            setError({
                field: "newPassword",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        if(!data.confirmPassword){
            setError({
                field: "confirmPassword",
                message: "Veuillez renseigner ce champs"
            })
            return
        }
        setError({
            field: "",
            message: ""
        })
        dispatch(resetPassword({
            token: token_url,
            data
        }))
    }

    return (
        <AuthLayout withBack={true} subtitle='Reinitialiser votre mot de passe' title='Mot de passe oublié' image={image2}>
            <form onSubmit={handleSubmit} className={"kbn-auth-form"}>
                {
                    isPasswordError ? (
                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                    ) : (
                        isPasswordSuccess ? (
                            <AlertModule status={"st-success"} title={"Reinitialisation du mot de passe!!!"} message={"Votre mot de passe a été. Vous pouvez vous reconnecter"}/>
                        ) : null
                    )
                }
                
                <div className='kbn-auth-form__fields'>
                    <div className='kbn-auth-form__field'>
                        <Textfield
                            id={"password"}
                            value={data.newPassword}
                            require={true}
                            changeValue={(val: string) => setData({...data, newPassword: val})}
                            type={"password"}
                            label={"Mot de passe"}
                            placeholder={"Veuillez insérer votre mot de passe"}
                            error={error}
                        />
                    </div>
                    <div className='kbn-auth-form__field'>
                        <Textfield
                            id={"confirmPassword"}
                            value={data.confirmPassword}
                            require={true}
                            changeValue={(val: string) => setData({...data, confirmPassword: val})}
                            type={"password"}
                            label={"Confirmation du mot de passe"}
                            placeholder={"Veuillez confirmer votre mot de passe"}
                            error={error}
                        />
                    </div>
                </div>

                <div className='kbn-auth-form__action'>
                    <Button btnType={"submit"} label={isPasswordLoading ? "Reinitialisation en cours..." : "Reinitialiser"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={isPasswordLoading} iconLeftClass={"icon-spin"}/>
                </div>

            </form>
        </AuthLayout>
    )
}
