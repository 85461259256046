import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, getProtecteItems, getUnProtecteItems, setProtecteItems, setUnProtecteItems, updateProtecteItems } from '../../utils';
import { AuthState } from '../../interface/GeneralStateInterface';
import axios from "axios"


const token = window.localStorage.getItem('bt-client-auth')
// const user = window.localStorage.getItem('bt-client-auth-user')


const AUTHURI = APIURL+"/auth"
const URI = APIURL+"/users"

const initialState: AuthState = {
    token: token ? token : "",
    me:  null,
    isLoginError: false,
    isLoginLoading: false,
    isLoginSuccess: false,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,
    isPasswordLoading: false,
    isPasswordError: false,
    isPasswordSuccess: false,
    isAvatarError: false,
    isAvatarLoading: false,
    isAvatarSuccess: false,
    isNotificationError: false,
    isNotificationLoading: false,
    isNotificationSuccess: false,
    isNotificationEditError: false,
    isNotificationEditLoading: false,
    isNotificationEditSuccess: false,
    message: null,
}

export const login = createAsyncThunk<any, Object>(
    'auth/login',
    async (content: any, thunkAPI) => {
        try {
            const response = await axios.post( AUTHURI+"/login", content)
            if(response){
                console.log(response)
                let tokens = response.data.token
                let user = {
                    lastName: response.data.lastName,
                    firstName: response.data.firstName,
                    email: response.data.email,
                }
                window.localStorage.setItem('bt-client-auth', tokens)
                window.localStorage.setItem('bt-client-auth-user', JSON.stringify(user))
                return response.data   
            }
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const register = createAsyncThunk<any, Object>(
    'auth/register',
    async (content: any, thunkAPI) => {
        try {
            let data = {
                url: `${AUTHURI}/register`,
                content
            }
            return await setUnProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const logout = createAsyncThunk<any, Object>(
    'auth/logout',
    async () => {
        window.localStorage.removeItem('bt-client-auth')
        window.localStorage.removeItem('bt-client-auth-user')
    }
)

export const getMe = createAsyncThunk<any, Object>(
    'auth/me',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${URI}`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updateInformation = createAsyncThunk<any, Object>(
    'auth/edit-information',
    async (content: any, thunkAPI) => {
        try {

            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await updateProtecteItems(data)
            
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const getPasswordResetMail = createAsyncThunk<any, Object>(
    'auth/password-reset-mail',
    async (content: any, thunkAPI) => {
        try {

            let data = {
                url: `${AUTHURI + `/get-password-reset-link?email=${content?.email}`}`,
                content,
            }
            return await getUnProtecteItems(data)
            
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const resetPassword = createAsyncThunk<any, Object>(
    'auth/password-reset',
    async (content: any, thunkAPI) => {
        try {
            
            const response = await axios.put( `${AUTHURI} /reset-password?sigature=${content.token}`, content.data)
        
            return response.data
            
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updatePassword = createAsyncThunk<any, Object>(
    'auth/edit-password',
    async (content: any, thunkAPI) => {
        try {

            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${URI}/update-password`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
            
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updateAvatar = createAsyncThunk<any, Object>(
    'auth/edit-avatar',
    async (content: any, thunkAPI) => {
        try {

            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            
            const response = await axios.post(`${URI}/update-avatar`, content, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${tokens}`,
                },
            });

            // console.log(response)

            return response.data
            
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.error)
        }
    }
)


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false

            state.isLoginError = false
            state.isLoginLoading = false
            state.isLoginSuccess = false

            state.isPasswordError = false
            state.isPasswordLoading = false
            state.isPasswordSuccess = false

            state.isAvatarError = false
            state.isAvatarLoading = false
            state.isAvatarSuccess = false

            state.isNotificationEditError = false
            state.isNotificationEditLoading = false
            state.isNotificationEditSuccess = false

            state.isNotificationLoading = false
            state.isNotificationError = false
            state.isNotificationSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(register.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(register.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.me = action.payload
        })
        .addCase(register.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.me = null
        })
        .addCase(login.pending, (state) => {
            state.isLoginLoading = true
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoginLoading = false
            state.isLoginSuccess = true
            state.me = action.payload
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoginLoading = false
            state.isLoginError = true
            state.message = action.payload
            state.me = null
        })
        .addCase(getMe.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.me = action.payload
        })
        .addCase(getMe.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.me = null
        })

        .addCase(updateInformation.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateInformation.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.me = action.payload
        })
        .addCase(updateInformation.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            state.me = null
        })

        .addCase(updatePassword.pending, (state) => {
            state.isPasswordLoading = true
        })
        .addCase(updatePassword.fulfilled, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordSuccess = true
            state.me = action.payload
        })
        .addCase(updatePassword.rejected, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordError = true
            state.message = action.payload
            state.me = null
        })

        .addCase(updateAvatar.pending, (state) => {
            state.isAvatarLoading = true
        })
        .addCase(updateAvatar.fulfilled, (state, action) => {
            state.isAvatarLoading = false
            state.isAvatarSuccess = true
            state.me = {...state.me, avatar: action.payload}
        })
        .addCase(updateAvatar.rejected, (state, action) => {
            state.isAvatarLoading = false
            state.isAvatarError = true
            state.message = action.payload
            // state.me = null
        })
        
        .addCase(getPasswordResetMail.pending, (state) => {
            state.isPasswordLoading = true
        })
        .addCase(getPasswordResetMail.fulfilled, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordSuccess = true
            state.me = action.payload
        })
        .addCase(getPasswordResetMail.rejected, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordError = true
            state.message = action.payload
            state.me = null
        })


        .addCase(resetPassword.pending, (state) => {
            state.isPasswordLoading = true
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordSuccess = true
            state.me = action.payload
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.isPasswordLoading = false
            state.isPasswordError = true
            state.message = action.payload
            state.me = null
        })

        .addCase(logout.fulfilled, (state) => {
            state.token = ""
        })
    },
})

export const { reset } = authSlice.actions
export default authSlice.reducer