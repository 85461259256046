import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../utils';
import { CartState } from '../../interface/GeneralStateInterface';

const URI = APIURL+"/client/product/cart"

const initialState: CartState = {
    carts: [],
    cart: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateLoading: false,
    isCreateSuccess: false,

    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,

    isDeleteError: false,
    isDeleteLoading: false,
    isDeleteSuccess: false,

    message: null,
    carts_info: null
}

export const getCarts = createAsyncThunk<any, Object>(
    'cart/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/list?pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCart = createAsyncThunk<any, Object>(
    'cart/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}'`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const setCart = createAsyncThunk<any, Object>(
    'cart/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateCart = createAsyncThunk<any, Object>(
    'cart/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens,
                content
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteCart = createAsyncThunk<any, Object>(
    'cart/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isCreateLoading = false
            state.isCreateSuccess = false
            state.isCreateError = false

            state.isEditLoading = false
            state.isEditSuccess = false
            state.isEditError = false

            state.isDeleteLoading = false
            state.isDeleteSuccess = false
            state.isDeleteError = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getCarts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCarts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.carts_info = action.payload
            state.carts = action.payload?.content
        })
        .addCase(getCarts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getCart.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCart.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.cart = action.payload
        })
        .addCase(getCart.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.cart = null
        })

        .addCase(updateCart.pending, (state) => {
            state.isEditLoading = true
        })
        .addCase(updateCart.fulfilled, (state, action) => {
            state.isEditLoading = false
            state.isEditSuccess = true
            state.cart = action.payload
            state.carts = [action.payload, ...state.carts?.filter((el: any) => el.id !== action.payload?.id)]
        })
        .addCase(updateCart.rejected, (state, action) => {
            state.isEditLoading = false
            state.isEditError = true
            state.message = action.payload
            // state.cart = null
        })

        .addCase(setCart.pending, (state) => {
            state.isCreateLoading = true
        })
        .addCase(setCart.fulfilled, (state, action) => {
            state.isCreateLoading = false
            state.isCreateSuccess = true
            state.carts = [action.payload, ...state.carts?.filter((el: any) => el.id !== action.payload?.id)]
            state.cart = action.payload
        })
        .addCase(setCart.rejected, (state, action) => {
            state.isCreateLoading = false
            state.isCreateError = true
            state.message = action.payload
            state.cart = null
        })

        .addCase(deleteCart.pending, (state) => {
            state.isDeleteLoading = true
        })
        .addCase(deleteCart.fulfilled, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteSuccess = true
            state.cart = action.payload
            state.carts = state.carts?.filter((el: any) => el.id !== action.payload?.id)
        })
        .addCase(deleteCart.rejected, (state, action) => {
            state.isDeleteLoading = false
            state.isDeleteError = true
            state.message = action.payload
            state.cart = null
        })

    },
})

export const { reset } = cartSlice.actions
export default cartSlice.reducer