import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { links } from '../../../../routes'
import { convertPrice, formatPrice } from '../../../utils/functions'
import { ThemeContext } from '../../../../context/ThemeContext'

export const ProductCard = ({item}:{item: any}) => {
    let navigate = useNavigate()
    const {
        devise,
    } = useContext(ThemeContext);
  return (
    <button type='button' className='bt-card' onClick={() => navigate(links.product + item.id)}>
        <div className='bt-card__image bt-card-image'>
            {
                item.image ? (
                    <img src={item.image} alt={item.id} />
                ) : (
                    item.images?.length > 0 && <img src={item.images[0]} alt={item.id} />
                )
            }
        </div>
        <div className='bt-card__container'>
            <div className='bt-card-info'>
                <strong>{item.label}</strong>
                <span>{`Catégorie: ${item.category?.parent?.label} - ${item.category?.label} `}</span>
            </div>
            <div className='bt-card-price'>
                <span >A partir de</span>
                <strong>{formatPrice(convertPrice(item.minPrice, devise), devise)}</strong>
                <p>Voir plus</p>
            </div>
        </div>
    </button>
  )
}
