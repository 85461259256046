import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { links } from '../../../../../../routes';
import { SettingMenuPage } from '../module/SettingMenu';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { updatePassword } from '../../../../../../app/feature/auth/auth.slice';
import { AlertModule } from '../../../../../components/src/alert';
import { Textfield } from '../../../../../components/src/field';
import { Button } from '../../../../../components/src/button';

const EditMyPassword = ({info}:{info: any}) => {
  const dispatch = useAppDispatch();
  const { isPasswordLoading, isPasswordError, isPasswordSuccess, message } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<any>({
    oldPassword: "", 
    newPassword: "", 
    confirmPassword: "",
    userId: info?.id
  })
  
  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const handleSumbit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.oldPassword){
        setError({
            field: "oldPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.newPassword){
        setError({
            field: "newPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.confirmPassword){
        setError({
            field: "confirmPassword",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError(null)
    dispatch(updatePassword(data))
} 

  return (
    <form className='bt-settings__box bt-settings-box'  onSubmit={handleSumbit}>
      <h1>Mot de passe</h1>
      {
        isPasswordSuccess ? (
          <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
        ) : (
            isPasswordError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
        )
      }
      <div className='bt-settings-box__fields bt-settings-box-fields'>
        <div className='bt-settings-box__field'>
          <Textfield
            id={"oldPassword"}
            value={data.oldPassword}
            require={true}
            changeValue={(value: any) => setData({...data, oldPassword: value})}
            type={"password"}
            label={"Ancien mot de passe"}
            placeholder={"Eg: *****"}
            error={error}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
            id={"newPassword"}
            value={data.newPassword}
            require={true}
            changeValue={(value: any) => setData({...data, newPassword: value})}
            type={"password"}
            label={"Nouveau mot de passe"}
            placeholder={"Eg: *****"}
            error={error}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
            id={"confirmPassword"}
            value={data.confirmPassword}
            require={true}
            changeValue={(value: any) => setData({...data, confirmPassword: value})}
            type={"password"}
            label={"Confirmation du nouveau mot de passe"}
            placeholder={"Eg: *****"}
            error={error}
          />
        </div>
      </div>
      <div className='bt-settings-box__action'>
        <Button btnType={"submit"}label={isPasswordLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={true} iconLeftClass={isPasswordLoading ? "kbn-icon-spin6" : "kbn-icon-save"}/>
      </div>
    </form>
  )
}

export const SettingPasswordPage = () => {
  const {info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting_password}`)
    }
  }, [token, navigate]);
  
  return (
    <MainLayout title='Mot de passe'>
      <div className='bt-page'>
        <div className='bt-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          <Link to={links.account}>
            <span>Mon compte</span>
          </Link>
          <span>/</span>
          <p className='active'>
            <span>Mot de passe</span>
          </p>
        </div>
        <div className='bt-settings'>
          <SettingMenuPage/>
          <div className='bt-settings__container'>
            {info ? <EditMyPassword info={info}/> : null}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
