import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from './context/ThemeContext';
import { WebRouter } from './routes';
// import { NotificationProvider } from './context/NotificationContext';
import { AuthContext } from './context/AuthContext';


function App() {
  const {
    theme,
    colorPalette,
    defaultDark,
    defaultTheme,
  } = useContext(ThemeContext);

  const [isConnected, setIsConnected] = useState<boolean>(true)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsConnected(window.navigator.onLine);
    }, 2000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <AuthContext>
      {/* <NotificationProvider> */}
        <div 
          className="bt-app"
          data-theme={defaultTheme === "ok" ? (defaultDark ? "dark" : "light") : theme}
          data-palette={colorPalette}
        >
          <WebRouter/>
          {
            !isConnected ? (
              <div className={`bt-offline-alert`}>
                <strong>Vous n'êtes pas connecté à internet!!</strong>
                <p>Veuillez verifier l'etat de votre connexion à internet et <button type='button' onClick={() => window.location.reload()}>réactualiser...</button></p>
              </div>
            ) : null
          }
        </div>
      {/* </NotificationProvider> */}
    </AuthContext>
  );
}

export default App;
