import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../../layout'
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { links } from '../../../../../../routes';
import { useNavigate, useParams } from 'react-router-dom';

export const CommandPage = () => {
  const {slug} = useParams()
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.command + slug}`)
    }
  }, [token, navigate, slug]);
  
  return (
    <MainLayout>
      <div className='bt-page'>
        
      </div>
    </MainLayout>
  )
}
