import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../../layout'
import { SettingMenuPage } from '../module/SettingMenu'
import { Link, useNavigate } from 'react-router-dom';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { links } from '../../../../../../routes';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { updateAvatar, updateInformation } from '../../../../../../app/feature/auth/auth.slice';
import { AlertModule, Toast } from '../../../../../components/src/alert';
import { Selectfield, Textfield } from '../../../../../components/src/field';
import { sexe } from '../../../../../utils/constant';
import { Button } from '../../../../../components/src/button';
import { capitalize } from '../../../../../utils/functions';
import { format } from 'date-fns';


const AvatarEdit = ({info}:{info: any}) => {
  const [fichier, setFichier] = useState<any>(null);
  const [size, setSize] = useState( 0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [error, setError] = useState<any>(null)

  const dispatch = useAppDispatch();
  const { isAvatarSuccess, isAvatarLoading, isAvatarError ,message } = useAppSelector((state) => state.auth);

  const handleCoverChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
      const file = e.target.files
      if(file && file.length > 0){
          let type: number = file[0].size
          setSize(type)
          let max: number = 1024 * 1024 * 4
          if(type < max){
            setFichier(file[0])
              // setCoverBase(file[0])
          }else{
              setError({
                field: "global",
                message: "Votre fichier ne doit pas dépasser 4MB"
              })
              return
          }
      }
  }



  useEffect(() => {
      if(fichier && info){
        const formData = new FormData();
        formData.append('avatar', fichier);

        let data = formData
        dispatch(updateAvatar(data))
        setError(null)
        setFichier(null)
      }
  }, [fichier, info]);

  useEffect(() => {
    if(error){
      setOpenModal(true)
    }
  }, [error]);

  useEffect(() => {
    if(isAvatarError){
      setOpenModal(true)
    }
  }, [isAvatarError]);

  useEffect(() => {
    if(isAvatarError){
      setOpenModal(true)
    }
  }, [isAvatarError]);


  return (
      <>
          <div className={"kbn-page-parametre-avatar"}>
            <div className='kbn-page-parametre-avatar__container'>
              <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleCoverChangeFile}/>
              <label className={"kbn-page-parametre-avatar-image"} htmlFor={"imageImg"}>
                  {
                    isAvatarLoading ? (
                      <i className="icon-spin"></i>
                    ) : (
                      fichier ? (
                        <img src={URL.createObjectURL(fichier)} alt="imageUrl"/>
                      ) : (
                        info?.avatar ? (
                          <img src={info?.avatar} alt="imageUrl"/>
                        ) :
                        <i className="icon-user"></i>
                      )
                    )
                  }
              </label>
            </div>
            <div className='kbn-page-parametre-avatar__actions'>
              <label htmlFor="imageImg" className='kbn-button is--neutral'>
                <i className="kbn-icon-camera"></i>
                <span>Modifier la photo</span>
              </label>
              {/* <button type='button' className='kbn-button is--danger' onClick={() => {
                let data = {avatar : null}
              }}>
                <i className="kbn-icon-x"></i>
                <span>Supprimer la photo</span>
              </button> */}
            </div>
             {
                openModal && error && (
                  <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
                    <p>{error?.message}</p>
                  </Toast>
                )
             }
             {
                openModal && isAvatarError && (
                  <Toast id={"avatar-alert"} status={"st-danger"} fnc={() => setOpenModal(false)}>
                    <p>{message}</p>
                  </Toast>
                )
             }
             {
                openModal && isAvatarSuccess && (
                  <Toast id={"avatar-alert"} status={"st-success"} fnc={() => setOpenModal(false)}>
                    <p>{"Votre photo de profile a été modifié"}</p>
                  </Toast>
                )
             }
          </div>
      </>

  )
}

const EditForm = ({info}:{info: any}) => {

  const dispatch = useAppDispatch();
  const { isEditLoading, isEditSuccess, isEditError ,message } = useAppSelector((state) => state.auth);
  const [data, setData] = useState<any>({
    firstName: info?.firstName || "",
    lastName: info?.lastName || "",
    email: info?.email || "",
    phoneNumber: info?.phoneNumber || "",
    gender: info?.gender || "",
    birthday: info?.birthday ? capitalize(format(new Date(info?.birthday), "yyyy-MM-dd")) : "",
  })

  const [error, setError] = useState<any>({
    field: "",
    message: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.lastName){
      setError({
          field: "lastName",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.firstName){
      setError({
          field: "firstName",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.email){
      setError({
          field: "email",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    setError({
        field: "",
        message: ""
    })
    dispatch(updateInformation(data))
  }


  return (
    <form className='bt-settings__box bt-settings-box' onSubmit={handleSubmit}>
      <h1>Mes informations</h1>
      {
        isEditSuccess ? (
          <AlertModule status={"st-success"} title={"Succès!!!"} message={"Vos informations a été modifiées avec succès"}/>
        ) : (
            isEditError ? (
                <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
        )
      }
      <div className='bt-settings-box__fields bt-settings-box-fields'>
        
        <div className='bt-settings-box__field'>
          <Textfield
              id={"lastName"}
              value={data.lastName}
              require={true}
              changeValue={(val: string) => setData({...data, lastName: val})}
              type={"text"}
              label={"Nom"}
              error={error}
              placeholder={"Veuillez insérer votre nom"}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
              id={"firstName"}
              value={data.firstName}
              require={true}
              changeValue={(val: string) => setData({...data, firstName: val})}
              type={"text"}
              label={"Prenom(s)"}
              error={error}
              placeholder={"Veuillez insérer votre(vos) prenom(s)"}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
              id={"email"}
              value={data.email}
              require={true}
              changeValue={(val: string) => setData({...data, email: val})}
              type={"text"}
              label={"Email"}
              error={error}
              placeholder={"Veuillez insérer votre email"}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
              id={"phoneNumber"}
              value={data.phoneNumber}
              require={false}
              changeValue={(val: string) => setData({...data, phoneNumber: val})}
              type={"phone"}
              label={"Telephone"}
              error={error}
              placeholder={"Veuillez insérer votre numero de telephone"}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Selectfield
              id={"gender"}
              value={data.gender}
              require={false}
              changeValue={(val: string) => setData({...data, gender: val})}
              tabs={sexe}
              label={"Genre"}
              error={error}
              placeholder={"Veuillez insérer votre genre"}
          />
        </div>
        <div className='bt-settings-box__field'>
          <Textfield
              id={"birthday"}
              value={data.birthday}
              require={false}
              changeValue={(val: string) => setData({...data, birthday: val})}
              type={"date"}
              label={"Date de naissance"}
              error={error}
              placeholder={"Veuillez insérer votre date de naissance"}
          />
        </div>
      </div>

      <div className='bt-settings-box__action'>
        <Button btnType={"submit"} label={isEditLoading ? "Modification en cours..." : "Enregistrer les modifications"} btnStyle={"is--primary"} active={!isEditLoading} withIconLeft={isEditLoading} iconLeftClass={"icon-spin"}/>
      </div>
    </form>
  )
}

export const SettingInfoPage = () => {
  const { info, token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.setting}`)
    }
  }, [token, navigate]);
  
  return (
    <MainLayout title='Mes informations'>
      <div className='bt-page'>
        <div className='bt-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          <Link to={links.account}>
            <span>Mon compte</span>
          </Link>
          <span>/</span>
          <p className='active'>
            <span>Mes informations</span>
          </p>
        </div>
        <div className='bt-settings'>
          <SettingMenuPage/>
          <div className='bt-settings__container'>
            {info ? <AvatarEdit info={info}/> : null}
            {info ? <EditForm info={info}/> : null}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
