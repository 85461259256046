import React from 'react'
import images from '../../../assets/images'

export const AdsCard = () => {
    
    const {image1} = images
  return (
    <a href='google.com' className='bt-ad is--long' target='_blank' rel="noreferrer">
        <img src={image1} alt="ads" />
        <span>Ads</span>
    </a>
  )
}
