import React from 'react'
import { links } from '../../../../../../routes'
import { NavLink } from 'react-router-dom'

export const SettingMenuPage = () => {

  const navItems = [
    {
      link: links.setting,
      label: "Mes informations",
      icon: "icon-user-pen"
    },
    {
      link: links.setting_password,
      label: "Mot de passe",
      icon: "icon-shield"
    },
    {
      link: links.setting_other,
      label: "Configuration",
      icon: " icon-pen-swirl"
    },
  ]
  return (
    <div className='bt-setting-menu'>
      <h2>Paramètres</h2>
      <div className='bt-setting-menu__list'>
        {
          navItems?.map((item: any, index: number) => (
            <div className='bt-setting-menu__item' key={index}>
              <NavLink to={item.link} end>
                <i className={item.icon}></i>
                <span>{item.label}</span>
              </NavLink>
            </div>
          ))
        }
      </div>
    </div>
  )
}
