import React, { ChangeEvent, useEffect, KeyboardEvent, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../../layout';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { reset, searchProducts } from '../../../../../app/feature/product/product.slice';
import { Button } from '../../../../components/src/button';
import { links } from '../../../../../routes';
import { AnnoyedCuate } from '../../../../assets/illustration';
import { ProductCard } from '../../../../components/src/card/ProductCard';

export const SearchPage = () => {
  const location = useLocation();
  const [search, setSearch] = useState<string>("")
  const searchParams = new URLSearchParams(location.search);
  const keyWords = searchParams.get('wordkeys')
  const typeSearch = searchParams.get('type')

  let navigate = useNavigate()

  console.log(keyWords, typeSearch)

  const dispatch = useAppDispatch();
  const {isLoading, isError, products, isSuccess, message}: any = useAppSelector((state) => state.product);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if(search) navigate(links.product_search + `?wordkeys=${search}&type=all`)
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    navigate(links.product_search + `?wordkeys=${e.target.value}&type=all`)
  };

  useEffect(() => {
    if(keyWords){
      dispatch(searchProducts(keyWords))
    }
    return () => {
      dispatch(reset())
    }
  }, [keyWords, dispatch])
  
  return (
    <MainLayout title="Rechercher...">
      <div className='bt-page'>
        <div className='bt-page-search'>
          <div className='bt-search-title'>
            <h1>Recherche...</h1>
          </div>
          <div className='bt-search-actions'>
              <i className="icon-search-alt"></i>
              <input 
                type="search" 
                placeholder='Mots clés (appuyer sur Entrée pour effectuer la recherche)' 
                value={keyWords || search} 
                onChange={handleChange} 
                onKeyDown={handleKeyDown}  />
          </div>

          {
            isLoading ? (
              <><i className="icon-spin"></i> <span>Chagement...</span></>
            ) : (
              isError ? (
                <div className={"kbn-user-error"}>
                    <div className={"kbn-user-error__container"}>
                      <i className="icon-triangle-exclamation"/>
                      <h2>Oupss!! Une erreur est survenue</h2>
                      <p>{message}</p>
                      <div className={"kbn-user-error__action"}>
                        <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
                      </div>
                    </div>
                </div>
              ) : (
                isSuccess && products ? (
                  products.length > 0 ? (
                    <div className='bt-search-grid'>
                      {
                        products.map((item: any, index: number) => (
                          <ProductCard item={item} key={index}/>
                        ))
                      }
                    </div>
                  ) : (
                    <div className='bt-search-empty'>
                      <AnnoyedCuate/>
                      <span>Aucun article trouvé!</span>
                    </div>
                  )
                ): null
              )
            )
          }
        </div>
      </div>
    </MainLayout>
  )
}
