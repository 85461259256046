import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../../routes'
import { UserAuthContext } from '../../../../../context/AuthContext'
import { useNavigate } from 'react-router-dom'

// const SummaryContainer = () => {
//   return (
//     <div></div>
//   )
// }

// const PaymentContainer = () => {
//   return (
//     <div></div>
//   )
// }

// const PaymentSummaryContainer = () => {
//   return (
//     <div></div>
//   )
// }

export const PaymentPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.commands}`)
    }
  }, [token, navigate]);
  
  return (
    <MainLayout>
      <div className='bt-page'>
        
      </div>
    </MainLayout>
  )
}
