import React, { useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import images from '../../../../assets/images'
import { LogoIcon } from '../../../../assets/illustration'
import { useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { Button } from '../../../../components/src/button'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { AdsCard } from '../../../../components/src/card/AdsCard'
import { ProductCard } from '../../../../components/src/card/ProductCard'
import { useAppDispatch, useAppSelector } from '../../../../../app/store'
import { getProducts, reset } from '../../../../../app/feature/product/product.slice'

interface SimpleBtn {
  onClick?: (val?: any) => any | void
}
export const SliderNextArrow:React.FC<SimpleBtn> = ({onClick}) => {
  return (
      <div className='bt-arrow' id='next' onClick={onClick} >
        <i className=" icon-arrow-narrow-right"></i>
      </div>
  );
};

export const SliderPrevArrow:React.FC<SimpleBtn>  = ({onClick}) => {
  return (
      <div className='bt-arrow' id='prev' onClick={onClick} >
        <i className="icon-arrow-narrow-left"></i>
      </div>
  );
};

const RecentProducts = ({products}:{products: any[]}) => {
  let setting2 = {
    dot: false,
    infinite: false,
    speed: 1000,
    slidesToShow: products?.length > 4 ? 4 : 1,
    slidesToScroll:  1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: products?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: products?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='bt-home-products'>
      <div className='bt-home-products__header'>
        <h2>Produits récemment ajoutés</h2>
      </div>
      <div className='bt-home-products__slider'>
        <Slider {...setting2}>
          {
            products.slice(0, 10).map((item, index) => (
              <div className='bt-home-products__slide' key={index}>
                <ProductCard item={item}/>
              </div>
            ))
          }
          <div className='bt-home-products__slide'>
            <AdsCard/>
          </div>
        </Slider>
      </div>
    </div>
  )
} 

const BonPlanProducts = ({products}:{products: any[]}) => {
  let setting2 = {
    dot: false,
    infinite:  false,
    speed: 1000,
    slidesToShow: products?.length > 4 ? 4 : 1,
    slidesToScroll:  1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: products?.length > 4 ? <SliderPrevArrow/> : <></>,
    nextArrow: products?.length > 4 ? <SliderNextArrow/> : <></>,
  }
  return (
    <div className='bt-home-products'>
      <div className='bt-home-products__header'>
        <h2>Les bons plans</h2>
        <p>Decouvrez nos produits les plus populaires</p>
      </div>
      <div className='bt-home-products__slider'>
        <Slider {...setting2}>
          <div className='bt-home-products__slide'>
            <AdsCard/>
          </div>
          {
            products.slice(0, 10).map((item, index) => (
              <div className='bt-home-products__slide' key={index}>
                <ProductCard item={item}/>
              </div>
            ))
          }
        </Slider>
      </div>
    </div>
  )
} 

export const HomePage = () => {
  const {image1, image2,} = images
  let navigate = useNavigate()
  let setting = {
    dot: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <SliderPrevArrow/>,
    nextArrow: <SliderNextArrow/>,
  }
  const [numberItem, setNumberItem] = useState<number>(25)
  const dispatch = useAppDispatch();
  const {isLoading, isError, products, message}: any = useAppSelector((state) => state.product);
  
  useEffect(() => {
    dispatch(getProducts({
      page: 0,
      size: numberItem,
    }))
    return () => {
      dispatch(reset())
    }
  }, [dispatch, numberItem])

  if(isLoading){
    return (
      <div className={"bt-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }
  // if(isError){
  //   return (
  //     <div className={"kbn-user-error"}>
  //         <div className={"kbn-user-error__container"}>
  //           <i className="icon-triangle-exclamation"/>
  //           <h2>Oupss!! Une erreur est survenue</h2>
  //           <p>{message}</p>
  //           <div className={"kbn-user-error__action"}>
  //             <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
  //           </div>
  //         </div>
  //     </div>
  //   )
  // }
  
  return (
    <MainLayout>
      <div className='bt-page'>
        <div className='bt-home-banner'>
          <div className="bt-home-banner__slider bt-home-banner-slider">
            <Slider {...setting}>
              <div className='bt-home-banner-slide'>
                <img src={image1} alt="side" />
                <div className='bt-home-banner-slide__container'>
                  <div className='bt-home-banner-slide__logo'>
                    <LogoIcon/>
                  </div>
                  <h2>Découvrez nos offres de promos exceptionnels</h2>
                  <div>
                    <Button btnType={'button'} label={'Parcourir notre catalogue'} btnStyle={'is--primary'} active={true} onClick={() => {}} />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="bt-home-banner__side bt-home-banner-side">
            <img src={image2} alt="side" />
            <button className='bt-home-banner-side__container' type='button' onClick={() => navigate(links.about)}>
              <div className='bt-home-banner-side__logo'>
                <LogoIcon/>
              </div>
              <h2>Découvrez nos produits et services</h2>
            </button>
          </div>
        </div>

        <RecentProducts products={products}/>
        <BonPlanProducts products={products}/>

        {/* <div className='bt-home-categories'>
          <div className='bt-home-categories__header'>
            <h2>Les mastodontes</h2>
            <p>Decouvrez nos produits les plus populaires</p>
          </div>
          <div className='bt-home-categories__list'>
            <button type='button' onClick={() => navigate(links.product_search+ `?wordkeys=category1&type=category`)} className={`bt-home-category is--two`}>
              <img src={image2} alt="category" />
              <div className='bt-home-category__container'>
                <div className='bt-home-category__logo'>
                  <LogoIcon/>
                </div>
                <h2>Cuisine</h2>
              </div>
            </button>
            <button type='button' onClick={() => navigate(links.product_search+ `?wordkeys=category1&type=category`)} className={`bt-home-category is--two`}>
              <img src={image2} alt="category" />
              <div className='bt-home-category__container'>
                <div className='bt-home-category__logo'>
                  <LogoIcon/>
                </div>
                <h2>Cuisine</h2>
              </div>
            </button>
            <button type='button' onClick={() => navigate(links.product_search+ `?wordkeys=category1&type=category`)} className={`bt-home-category is--three`}>
              <img src={image2} alt="category" />
              <div className='bt-home-category__container'>
                <div className='bt-home-category__logo'>
                  <LogoIcon/>
                </div>
                <h2>Cuisine</h2>
              </div>
            </button>
            <button type='button' onClick={() => navigate(links.product_search+ `?wordkeys=category1&type=category`)} className={`bt-home-category is--three`}>
              <img src={image2} alt="category" />
              <div className='bt-home-category__container'>
                <div className='bt-home-category__logo'>
                  <LogoIcon/>
                </div>
                <h2>Cuisine</h2>
              </div>
            </button>
            <button type='button' onClick={() => navigate(links.product_search+ `?wordkeys=category1&type=category`)} className={`bt-home-category is--three`}>
              <img src={image2} alt="category" />
              <div className='bt-home-category__container'>
                <div className='bt-home-category__logo'>
                  <LogoIcon/>
                </div>
                <h2>Cuisine</h2>
              </div>
            </button>
          </div>
        </div> */}

        <div className='bt-home-products'>
          <div className='bt-home-products__header'>
            <h2>Produits Recommandés</h2>
            {/* <p>Decouvrez nos produits les plus populaires</p> */}
          </div>
          
            
              <div className='bt-home-products__list'>
                {
                  products?.map((item: any, index: number) => (
                    <ProductCard item={item} key={index}/>
                  ))
                }
              </div>
            
          {
            products?.length > 25 ? (
              <button className='bt-home-products__more bt-home-products-more' type='button' onClick={() => setNumberItem(numberItem + 20)}>
                <div className='bt-home-products-more-line'></div>
                <span>Afficher plus</span>
                <div className='bt-home-products-more-line'></div>
              </button>
            ) : null
          }
        </div>
      </div>
    </MainLayout>
  )
}
