
import { AboutPage, AccountPage, BlogPage, BlogsPage, CartPage, CommandPage, CommandsPage, ErrorPage, FAQPage, HomePage, LoginPage, MentionPage, PaymentPage, ProductPage, ProductsPage, RecoverPage, RegisterPage, SearchPage, SettingInfoPage, SettingOtherPage, SettingPasswordPage, TermsPage } from "../../views/pages";
import { links } from "./links";

export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.about,
        Component: AboutPage
    },
    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.recover,
        Component: RecoverPage
    },
    {
        path: links.setting,
        Component: SettingInfoPage
    },
    {
        path: links.setting_password,
        Component: SettingPasswordPage
    },
    {
        path: links.setting_other,
        Component: SettingOtherPage
    },
    {
        path: links.blogs,
        Component: BlogsPage
    },
    {
        path: links.blogs,
        Component: BlogsPage
    },
    {
        path: links.blog + ":slug",
        Component: BlogPage
    },
    {
        path: links.cart,
        Component: CartPage
    },
    {
        path: links.commands,
        Component: CommandsPage
    },
    {
        path: links.command + ":slug",
        Component: CommandPage
    },
    {
        path: links.faq,
        Component: FAQPage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.mention,
        Component: MentionPage
    },
    {
        path: links.products,
        Component: ProductsPage
    },
    {
        path: links.product_search,
        Component: SearchPage
    },
    {
        path: links.product + ":slug",
        Component: ProductPage
    },
    {
        path: links.product_payment,
        Component: PaymentPage
    },
    {
        path: links.register,
        Component: RegisterPage
    },
    {
        path: links.terms + ":slug",
        Component: TermsPage
    },
    {
        path: links.error,
        Component: ErrorPage
    },
]