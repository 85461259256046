import React, { FormEvent, useContext, useEffect, useState } from 'react'
import images from '../../../../assets/images'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { Button } from '../../../../components/src/button'
import { Textfield } from '../../../../components/src/field'
import { AlertModule } from '../../../../components/src/alert'
import { AuthLayout } from '../../../../layout'
import { useAppDispatch, useAppSelector } from '../../../../../app/store'
import { register } from '../../../../../app/feature/auth/auth.slice'
import { UserAuthContext } from '../../../../../context/AuthContext'

export const RegisterPage = () => {
  const [data, setData] = useState<any>({
    email: "", password: "", firstName: "", lastName: "", confirmPassword: ""
  })
  const [error, setError] = useState({
      field: "",
      message: ""
  })
  const {image2} = images

  let navigate = useNavigate()

  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateError, isCreateSuccess, message } = useAppSelector((state) => state.auth);
  const { token } = useContext(UserAuthContext); 
   
  useEffect(() => {
    if(token){
      navigate(links.home)
    }
  }, [token, navigate]);
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.lastName){
        setError({
            field: "lastName",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.firstName){
        setError({
            field: "firstName",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.password){
        setError({
            field: "password",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError({
        field: "",
        message: ""
    })
    dispatch(register(data))
  }

  useEffect(() => {
    if(isCreateSuccess){
      navigate(links.login, {
        state: {
          message: "Votre compte a été créé. Un mail de validation vous a été envoyé",
          status: "success"
        }
      })
    }
  }, [message, isCreateSuccess, navigate])

  return (
    <AuthLayout withBack={true} subtitle='Bienvenue sur Berong Trade' title='Inscription' image={image2}>
      <form onSubmit={handleSubmit} className={"kbn-auth-form"}>
          {
            isCreateError ? (
              <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
          }
          <div className='kbn-auth-form__fields'>
            <div className='kbn-auth-form__field'>
              <Textfield
                  id={"lastName"}
                  value={data.lastName}
                  require={true}
                  changeValue={(val: string) => setData({...data, lastName: val})}
                  type={"text"}
                  label={"Nom"}
                  error={error}
                  placeholder={"Veuillez insérer votre nom"}
              />
            </div>
            <div className='kbn-auth-form__field'>
              <Textfield
                  id={"firstName"}
                  value={data.firstName}
                  require={true}
                  changeValue={(val: string) => setData({...data, firstName: val})}
                  type={"text"}
                  label={"Prenom(s)"}
                  error={error}
                  placeholder={"Veuillez insérer votre(vos) prenom(s)"}
              />
            </div>
            <div className='kbn-auth-form__field'>
              <Textfield
                  id={"email"}
                  value={data.email}
                  require={true}
                  changeValue={(val: string) => setData({...data, email: val})}
                  type={"text"}
                  label={"Email"}
                  error={error}
                  placeholder={"Veuillez insérer votre email"}
              />
            </div>
            <div className='kbn-auth-form__field'>
                <Textfield
                    id={"password"}
                    value={data.password}
                    require={true}
                    changeValue={(val: string) => setData({...data, password: val})}
                    type={"password"}
                    label={"Mot de passe"}
                    placeholder={"Veuillez insérer votre mot de passe"}
                    error={error}
                />
            </div>
            <div className='kbn-auth-form__field'>
                <Textfield
                    id={"confirmPassword"}
                    value={data.confirmPassword}
                    require={true}
                    changeValue={(val: string) => setData({...data, confirmPassword: val})}
                    type={"password"}
                    label={"Confirmation du mot de passe"}
                    placeholder={"Veuillez confirmer votre mot de passe"}
                    error={error}
                />
            </div>
          </div>

          <div className='kbn-auth-form__info'>
            <Link to={links.terms} className='kbn-link is--primary'><span>En continuant vous acceptez nos differentes conditions d'utilisation et  de protection de données</span></Link>
          </div>
          <div className='kbn-auth-form__action'>
            <Button btnType={"submit"} label={isCreateLoading ? "Création en cours..." : "Créer un compte"} btnStyle={"is--primary"} active={!isCreateLoading} withIconLeft={isCreateLoading} iconLeftClass={"icon-spin"}/>
          </div>

          <div className='kbn-auth-form__info'>
           <p><span>J'ai déjà un compte?</span> <Link to={links.login} className='kbn-link is--primary'><span> Se connecter</span></Link></p>
          </div>
      </form>
    </AuthLayout>
  )
}
