import React, { useContext } from 'react'
import { MainLayout } from '../../../../../layout'
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { SettingMenuPage } from '../module/SettingMenu';
import { Link } from 'react-router-dom';
import { links } from '../../../../../../routes';
import { ThemeContext, devises, langues } from '../../../../../../context/ThemeContext';

export const SettingOtherPage = () => {
  const { token } = useContext(UserAuthContext);
  const {
      theme,
      defaultDark,
      defaultTheme,
      swichtDefautTheme,
      switchTheme,
      colorPalette,
      switchPaletteColor,
      langue,
      setLangue,
      devise, setDevise,
  } = useContext(ThemeContext);

  let schemes =  [
    {
        id: "default",
        color: "is--default"
    },
    {
        id: "palette2",
        color: "is--palette2"
    },
    {
        id: "palette3",
        color: "is--palette3"
    },
    {
        id: "palette4",
        color: "is--palette4"
    },
    {
        id: "palette5",
        color: "is--palette5"
    },
  ]

  return (
    <MainLayout title='Configuration'>
      <div className='bt-page'>
        <div className='bt-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          {
            token ? (
              <>
                <Link to={links.account}>
                  <span>Mon compte</span>
                </Link>
                <span>/</span>
              </>
            ) : null
          }
          <p className='active'>
            <span>{token? "Configuration" : "Paramètres"}</span>
          </p>
        </div>
        <div className='bt-settings'>
          {
            token ? <SettingMenuPage/> : null
          }
          <div className='bt-settings__container'>
            <h1>Configuration</h1>

            <div className='kbn-page-settings'>
              <div className='kbn-page-setting'>
                <h2>Langue</h2>
                <div className='kbn-page-setting__fonts'>
                  {
                    langues.map((item, index) => (
                      <button type='button' className={`kbn-page-setting__font ${item.value} ${langue === item.value ? "active" : ""}`} key={index} onClick={() => setLangue(item.value)}>
                          <strong><i className="icon-globe-alt"></i></strong>
                          <span>{item.label}</span>
                      </button>
                    ))
                  }
                </div>
              </div>
              <div className='kbn-page-setting'>
                <h2>Devise</h2>
                <div className='kbn-page-setting__fonts'>
                  {
                    devises.map((item, index) => (
                      <button type='button' className={`kbn-page-setting__font ${item.value} ${devise === item.value ? "active" : ""}`} key={index} onClick={() => setDevise(item.value)}>
                          <strong><i className="icon-credit-card"></i></strong>
                          <span>{item.label}</span>
                      </button>
                    ))
                  }
                </div>
              </div>
              <div className='kbn-page-setting'>
                <h2>Schema de couleurs</h2>
                <div className='kbn-page-setting__colors'>
                  {
                    schemes.map((item: any, index: number) => (
                      <button key={index} className={`kbn-page-setting-color ${item.id === colorPalette ? "active" : ""}`} onClick={() => switchPaletteColor(item.id)}>
                        <div className={`kbn-page-setting-color__container ${item.color}`}></div>
                      </button>
                    ))
                  }
                </div>
              </div>
              <div className='kbn-page-setting'>
                <h2>Thème : {defaultTheme === "ok" ? (defaultDark ? "Il fait nuit" : "Il fait jour") : (theme === "dark" ? "Thème Sombre" : "Thème Clair")}</h2>
                <div className={"kbn-page-setting__theme kbn-page-setting-theme"}>
                    <button type={"button"} className={"kbn-page-setting-theme-switch"} onClick={switchTheme}>
                        <div className={`kbn-page-setting-theme-switch__content ${defaultTheme === "ok" ? (defaultDark ? "is--active" : "") : (theme === "dark" ? "is--active" : "")}`}>
                          {/* <i className={defaultTheme === "ok" ? (defaultDark ? "kbn-icon-moon" : "icon-weather-sun-1") : (theme === "dark" ? "kbn-icon-moon" : "icon-weather-sun-1")}></i> */}
                        </div>
                    </button>
                    <button type={"button"} className={"kbn-page-settingn-theme-default"} onClick={swichtDefautTheme}>
                        <span>System thème</span>
                    </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </MainLayout>
  )
}
