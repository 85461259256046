import React, { useContext, useEffect, useState } from 'react'
import { MainLayout } from '../../../../layout'
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { links } from '../../../../../routes';
import { ModalLayout } from '../../../../components/src/modal';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { Button } from '../../../../components/src/button';
import { deleteCart, getCarts, reset, setCart } from '../../../../../app/feature/cart/cart.slice';
import { AnnoyedCuate } from '../../../../assets/illustration';
import { convertPrice, formatPrice } from '../../../../utils/functions';
import { ThemeContext } from '../../../../../context/ThemeContext';
import { Toast } from '../../../../components/src/alert';

const DeleteModal = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  const dispatch = useAppDispatch();
  const {isDeleteSuccess, isDeleteLoading, isDeleteError, message } = useAppSelector((state) => state.cart);
  
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    if(isDeleteSuccess && statut){
      // window.location.reload()
    }
    setStatut(false)
    closeFnc(false)
  }

  return (
    
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isDeleteLoading} fnc={() => closeModal()}>
      <div className={"kbn-modal-form"}>
        {
          statut && isDeleteSuccess ? (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--success"}>
                        <i className="icon-party-bell"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un produit du panier</h2>
                        <p>L'element a bien été supprimé avec succès</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--danger"}>
                      <i className="icon-trash"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un produit du panier</h2>
                        {
                            statut && isDeleteError ? (
                              <p>
                                  <strong>Ouppss!!!</strong> <br/>
                                  <span>{message}</span>
                              </p>
                            ) : (
                                <p>Souhaitez-vous vraiment supprimer cet élément</p>
                            )
                        }
                        <div className={"kbn-modal-form-status__action"}>
                            {
                                statut && isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-trash"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteCart(item.id))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Supprimer"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-trash"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteCart(item.id))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

export const totalItems = (tabs: any[]) => {
  let sum: number = 0

  tabs.forEach((el: any) => {
    sum = sum + el.quantity
  })

  return sum
}

const CartCard = ({item, fnc , delFnc, selectedtems} : {item: any, fnc: (val?: any) => any | void, delFnc: (val?: any) => any | void, selectedtems: any[]}) => {
  const {
    devise,
  } = useContext(ThemeContext);
  const [qte, setQte] = useState<number>(1)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [itemModal, setItemModal] = useState<any>(null)
  const { isEditLoading, isEditError, isEditSuccess, message } = useAppSelector((state) => state.cart);

  let navigate = useNavigate()

  const dispatch = useAppDispatch();
  const [isNewNotif, setIsNewNotif] = useState<boolean>(false)

  useEffect(() => {
    if(item){
      setQte(item.quantity)
    }
  }, [item])
  
  useEffect(() => {
    if(isEditError){
      setIsNewNotif(true)
    }
  }, [isEditError])
  
  useEffect(() => {
    if(isEditSuccess){
      setIsNewNotif(true)
    }
  }, [isEditSuccess])

  return (
    <div className={`bt-cart-product ${selectedtems?.filter((el: any) => el.id === item.id).length > 0 ? "is--selected" : " "}`}>
      <button className={`bt-cart-product-box ${selectedtems?.filter((el: any) => el.id === item.id).length > 0 ? "is--selected" : " "}`} onClick={() =>{
        if(selectedtems?.filter((el: any) => el.id === item.id).length > 0){
          delFnc(item)
        }else{
          fnc(item)
        }
      }}>
        <div className='bt-cart-product-box__container'></div>
      </button>
      <div className='bt-cart-product__container'>
        <button type='button' className='bt-cart-product__image' onClick={() => navigate(links.product +item.product?.id)}>
          <img src={item.product?.image || ""} alt="cart-item" />
        </button>
        <div className='bt-cart-product__contain'>
        
        <button type='button' className='bt-cart-product-info' onClick={() => navigate(links.product +item.product?.id)}>
          <strong>{item.product?.label}</strong>
        </button>

        <div className='bt-cart-product-price'>
          <span >A partir de {formatPrice(convertPrice(item.product?.minPrice, devise), devise)}</span>
          <div className='bt-cart-product-price__qte'>
            <button type='button' className={`${isEditLoading ?  "is--disable" : item.quantity <= 0 ? "is--disable" : ""}`} onClick={() => {
              if(qte > 1){
                setQte(qte - 1)
                let data = {
                  productId: item?.product?.id,
                  quantity: qte - 1
                }
                dispatch(setCart(data))
              }else{
                setItemModal(null)
                setItemModal(item)
                setDeleteModal(true)
              }
            }}>
              <i className="icon-minus"></i>
            </button>
            <span>{qte}</span>
            <button type='button' className={`${isEditLoading ?  "is--disable" : qte >= 50 ? "is--disable" : ""}`} onClick={() => {
              if(qte < 50){
                setQte(qte + 1)
                let data = {
                  productId: item?.product?.id,
                  quantity: qte + 1
                }
                dispatch(setCart(data))
              }
            }}>
              <i className="icon-plus"></i>
            </button>
          </div>
        </div>

        </div>
        <div className='bt-cart-product__action'>
          <button type='button' onClick={() => {
            setItemModal(null)
            setItemModal(item)
            setDeleteModal(true)
          }}>
            <i className="icon-trash"></i>
            <span className='bt-tooltip'>Supprimer</span>
          </button>
        </div>
      </div>
      {isEditError && isNewNotif && (
        <Toast id={"notification"} status={'st-danger'} fnc={setIsNewNotif}>
          <span>{message}</span>
        </Toast>
      )}
      {isEditSuccess && isNewNotif && (
        <Toast id={"notification"} status={'st-success'} fnc={setIsNewNotif}>
          <span>{"La quantité a été modifiée"}</span>
        </Toast>
      )}
      {itemModal && deleteModal && <DeleteModal item={itemModal} closeFnc={(val: boolean) => setDeleteModal(val)}/>}
    </div>
  )
}

export const CartPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.cart}`)
    }
  }, [token, navigate]);

  

  const dispatch = useAppDispatch();
  const [numberItem, setNumberItem] = useState<number>(20)
  const [selectedtems, setSelectedItems] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const { isLoading, isSuccess , isError, carts, carts_info, message } = useAppSelector((state) => state.cart);

  useEffect(() => {
    if(page && page >= 0 && numberItem && numberItem > 1){
      dispatch(getCarts({
        page: page - 1,
        size: numberItem,
      }))
    }
    return () => {
      dispatch(reset())
    }
  }, [dispatch, page, numberItem])

  if(isLoading){
    return (
      <div className={"bt-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError){
    return (
      <div className={"kbn-user-error"}>
          <div className={"kbn-user-error__container"}>
            <i className="icon-triangle-exclamation"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kbn-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }

  return (
    <MainLayout title='Mon panier' here='cart'>
      <div className='bt-page'>
        <div className='bt-page-cart'>
          <div className='bt-cart-title'>
            <h1>🛒</h1>
            <strong>Mon panier</strong>
          </div>
          {
            isSuccess && carts ? (
              carts?.length > 0 ? (
                <div className='bt-cart-container'>
                  <div className='bt-cart-products'>
                    <div className='bt-cart-products__header'>
                      <div className=''>
                        <p>Quantité totale: <strong>{totalItems(carts)}</strong> dans le panier</p>
                      </div>
                      <div className='bt-cart-products__toAffiche'>
                        <span>Nombre à afficher</span> <input type="number" value={numberItem} onChange={(e) => setNumberItem(parseInt(e.target.value))} min={2}/>
                      </div>
                      {
                        carts_info?.totalPages > 1 ? (
                          <div className='bt-cart-products__page'>
                            <span>Page: </span>
                            <input type="number" value={page} onChange={(e) => setPage(parseInt(e.target.value))} min={1} />
                            <span>/ {carts_info?.totalPages || ""}</span>
                          </div>
                        ) : (
                          <div className='bt-cart-products__page'>
                            <span>Page: 1/1</span>
                          </div>
                        )
                      }
                    </div>
                    <div className='bt-cart-products__grid'>
                      {
                        carts.map((item: any, index: number) => (
                          <CartCard item={item} selectedtems={selectedtems} fnc={(val: any) => setSelectedItems((list) => [...list, val])} key={index} delFnc={(val: any) => setSelectedItems(selectedtems.filter((el: any) => el.id!== val.id))}/>
                        ))
                      }
                    </div>
                  </div>
                  <div className='bt-cart-recap'>
                    <div className='bt-cart-recap__container'>
                      <h2>Recapitulatif</h2>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='bt-cart-empty'>
                  <AnnoyedCuate/>
                  <span>Le panier est vide!</span>
                </div>
              )
            ) : null
          }
        </div>
      </div>
    </MainLayout>
  )
}
