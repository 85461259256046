export const links = {
    home: "/",
    about: "/about-us",
    services: "/services",
    terms: "/terms/",
    faq: "/you-need-help",
    mention: "/legal-notice",
    gallery: "/gallery",
    blogs: "/blog",
    blog: "/article/",

    recover: "/password-reset",

    login: "/login",
    register: "/create-an-account",

    account: "/my-account",
    notification: "/notification",
    
    setting: "/information-setting",
    setting_password: "/password-setting",
    setting_other: "/settings",

    cart: "/cart",
    commands: "/my-orders",
    command: "/order-tracking/",

    products: "/products/",
    product: "/product/",
    product_payment: "/buy-product/",
    product_search: "/search",

    error: "/*"

}