import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, getUnProtecteItems} from '../../utils';
import { ProductState } from '../../interface/GeneralStateInterface';

const URI = APIURL+"/product"

const initialState: ProductState = {
    products: [],
    product: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    message: null,
    products_info: null
}


export const getProducts = createAsyncThunk<any, Object>(
    'product/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/list?pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const searchProducts = createAsyncThunk<any, Object>(
    'product/search',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/search?query=${content}`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getProduct = createAsyncThunk<any, Object>(
    'product/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)




export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false


            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getProducts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getProducts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products_info = action.payload
            state.products = action.payload?.content
        })
        .addCase(getProducts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        
        .addCase(searchProducts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(searchProducts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products = action.payload
        })
        .addCase(searchProducts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

        .addCase(getProduct.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getProduct.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.product = action.payload
        })
        .addCase(getProduct.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.product = null
        })

    },
})

export const { reset } = productSlice.actions
export default productSlice.reducer