import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/src/button'
import { links } from '../../../../../routes'
import { Textfield } from '../../../../components/src/field'
import { AuthLayout } from '../../../../layout'
import { AlertModule } from '../../../../components/src/alert'
import { useAppDispatch, useAppSelector } from '../../../../../app/store'
import { getPasswordResetMail, login } from '../../../../../app/feature/auth/auth.slice'
import { UserAuthContext } from '../../../../../context/AuthContext'
import { ModalLayout } from '../../../../components/src/modal'

const PasswordForget = ({closeFnc}:{closeFnc: (val: boolean) => void}) => {
  const [data, setData] = useState<any>({
    email: "",
  })

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })

  const dispatch = useAppDispatch();
  const { isPasswordLoading, isPasswordError, isPasswordSuccess, message } = useAppSelector((state) => state.auth);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  
    if(!data.email){
        setError({
            field: "email",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    setError(null)
    setCloseM(true)
    dispatch(getPasswordResetMail(data))
  }

  const closeModal = () => {
    setData(null)
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} isOpen={true} id={"fogotter-modal"} withClose={!isPasswordLoading} fnc={() => closeModal()}>
     {
      isPasswordSuccess && closeM ? (
        <div className={"kbn-modal-form-status"}>
            <div className={"kbn-modal-form-status__container"}>
                <div className={"kbn-modal-form-status__icon is--success"}>
                    <i className="icon-party-bell"/>
                </div>
                <div className={"kbn-modal-form-status__text"}>
                    <h2>Mot de passe oublié?</h2>
                    <p>Un mail pour reinitialiser votre mot de passe vous a été envoyé</p>
                    <div className={"kbn-modal-form-status__action"}>
                      <Button
                        btnType={"button"}
                        label={"Fermer..."}
                        btnStyle={"is--neutral"}
                        active={true}
                        withIconLeft={false}
                        onClick={() => {
                            closeModal()
                        }}
                      />
                    </div>
                </div>
            </div>
        </div>
      ) : (
        <div className={"kbn-modal-form"}>
          <div className={"kbn-modal-form__header"}>
            <h2>Mot de passe oublié</h2>
            <p>Veuiller entrer votre addresse mail, nous vous enverrons un mail contenant les instructions pour reinitialiser votre mot de passe</p>
          </div>
          <form className={"kbn-modal-form__container"} onSubmit={handleSubmit}>
            {
              closeM && isPasswordError ? (
                  <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
              <div className={"kbn-modal-form__fields"}>
                <div className={"kbn-modal-form__field"}>
                  <Textfield
                    id={"email"}
                    value={data.email}
                    require={false}
                    changeValue={(value: any) => setData({...data, email: value})}
                    type={"email"}
                    label={"Email"}
                    placeholder={"Eg: jean@email.com"}
                    error={error}
                  />
                </div>

                <div className={"kbn-modal-form__action"}>
                  <Button btnType={"submit"} label={isPasswordLoading ? "Envoi en cours..." : "Enveyer"} btnStyle={"is--primary"} active={!isPasswordLoading} withIconLeft={true} iconLeftClass={isPasswordLoading ? "icon-spin" : ""} />
                </div>

              </div>
          </form>
        </div>
      )
     }
    </ModalLayout>
  )
}

export const LoginPage = () => {
  const [data, setData] = useState<any>({
    email: "", password: ""
  })
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false) 
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get('url')
  const active = searchParams.get('active')
  
  let navigate = useNavigate()
  
  const dispatch = useAppDispatch();
  const { isLoginLoading, isLoginError, isLoginSuccess, message } = useAppSelector((state) => state.auth);
  const { token, setRedirectTo } = useContext(UserAuthContext); 
   
  useEffect(() => {
    if(token){
        navigate(url || links.home)
    }
  }, [token, navigate, url]);

  useEffect(() => {
    setRedirectTo(url || links.home)
  }, [setRedirectTo, url]);

  const [error, setError] = useState({
      field: "",
      message: ""
  })

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.email){
        setError({
          field: "email",
          message: "Veuillez renseigner ce champs"
        })
        return
    }
    if(!data.password){
        setError({
            field: "password",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setError({
        field: "",
        message: ""
    })
    dispatch(login(data))
  }

  useEffect(() => {
    if(isLoginSuccess){
      window.location.reload()
    }
  }, [navigate, isLoginSuccess]);

  return (
    <AuthLayout withBack={true} subtitle='Heureux de vous revoir'>
      <form onSubmit={handleSubmit} className={"kbn-auth-form"}>
          {
            isLoginError ? (
              <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
          }
          {
            active ? (
              <AlertModule status={"st-success"} title={"Compte activé!!!"} message={"Votre compte est maintenant actif. Vous pouvez vous connecter."}/>
            ) : null
          }
          <div className='kbn-auth-form__fields'>
            <div className='kbn-auth-form__field'>
              <Textfield
                  id={"email"}
                  value={data.email}
                  require={true}
                  changeValue={(val: string) => setData({...data, email: val})}
                  type={"text"}
                  label={"Email"}
                  error={error}
                  placeholder={"Veuillez insérer votre email"}
              />
            </div>
            <div className='kbn-auth-form__field'>
                <Textfield
                    id={"password"}
                    value={data.password}
                    require={true}
                    changeValue={(val: string) => setData({...data, password: val})}
                    type={"password"}
                    label={"Mot de passe"}
                    placeholder={"Veuillez insérer votre mot de passe"}
                    error={error}
                />
            </div>
          </div>

          <div className='kbn-auth-form__info'>
            <button className='kbn-link is--primary' type='button' onClick={() => setOpenPasswordModal(true)}><span>Mot de passe oublié?</span></button>
          </div>
          <div className='kbn-auth-form__action'>
            <Button btnType={"submit"} label={isLoginLoading ? "Connexion en cours..." : "Connexion"} btnStyle={"is--primary"} active={!isLoginLoading} withIconLeft={isLoginLoading} iconLeftClass={"icon-spin"}/>
          </div>

          <div className='kbn-auth-form__info'>
           <p><span>Je n'ai pas de compte?</span> <Link to={links.register} className='kbn-link is--primary'><span> Créer un compte</span></Link></p>
          </div>
      </form>
        {
          openPasswordModal && <PasswordForget closeFnc={(val: boolean) => setOpenPasswordModal(val)}/>
        }
    </AuthLayout>
  )
}
