import React, {createContext, useEffect} from "react";
import useLocalStorage from "use-local-storage";

export const ThemeContext = createContext<null | any>(null)


interface ThemeContextParams {
    children?: React.ReactNode
}

export const langues = [
    {
        label: "Français",
        value: "fr-FR",
    },
    {
        label: "English",
        value: "en-US"
    },
    {
        label: "Chinese",
        value: "zh-Hans"
    },
]

export const devises = [
    {
        label: "Dollar US",
        value: "dollar-us",
        symbol: "$"
    },
    {
        label: "Euro",
        value: "euro",
        symbol: "€"
    },
    {
        label: "Yuan/Renminbi",
        value: "yuan",
        symbol: "¥"
    },
    {
        label: "Francs CFA (XOF)",
        value: "xof",
        symbol: "XOF"
    },
]

export const ThemeProvider: React.FC<ThemeContextParams> = ({children}) => {
    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');
    const [colorPalette, setColorPalette] = useLocalStorage('colorPalette', "default");
    const [devise, setDevise] = useLocalStorage('devise', "yuan");
    const [langue, setLangue] = useLocalStorage('langue', "fr-FR");
    const [defaultTheme, setDefaultTheme] = useLocalStorage('isDefaultTheme', "ok");

    const switchTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setDefaultTheme("nope")
        setTheme(newTheme)
    }
    const swichtDefautTheme = () => {
        setDefaultTheme("ok")
    }
    const switchPaletteColor = (value : "default" | "palette2" | "palette3" | "palette4" | "palette5") => {
        setColorPalette(value)
    }
    
    useEffect(() => {
        let body: any = document.querySelector("body")
        if(theme === "light"){
            body && (body.style.background = "#fff")
        }else{
            body && (body.style.background = "#121212")
        }
    }, [theme]);

    

    return (
        <ThemeContext.Provider value={{
            theme,
            colorPalette,
            defaultDark,
            defaultTheme,
            switchTheme,
            swichtDefautTheme,
            switchPaletteColor,
            devise, setDevise,
            langue, setLangue

        }}>
            {children}
        </ThemeContext.Provider>
    );
}