import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../../layout'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../../routes'
import { CommandCarde } from '../../account'
import { SliderNextArrow, SliderPrevArrow } from '../../../main'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import { UserAuthContext } from '../../../../../../context/AuthContext'

export const CommandsPage = () => {
  const { token } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.commands}`)
    }
  }, [token, navigate]);

  let setting2 = {
    dot: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    cssEase: 'linear',
    autoplay: false,
    autoplaySpeed: 2000,
    prevArrow: <SliderPrevArrow/>,
    nextArrow: <SliderNextArrow/>,
  }
  return (
    <MainLayout title='Mes commandes'>
      <div className='bt-page'>
        <div className='bt-arianne'>
          <Link to={links.home}>
            <span>Accueil</span>
          </Link>
          <span>/</span>
          <Link to={links.account}>
            <span>Mon compte</span>
          </Link>
          <span>/</span>
          <p className='active'>
            <span>Mes commandes</span>
          </p>
        </div>
        <div className='bt-page-title'>
          <h2>Mes commandes</h2>
        </div>
        <div className='bt-page-container'>
          <div className='bt-page-filters'>
            <Slider {...setting2}>
              <div className='bt-page-filter'>
                <button className='kbn-chip' type='button'>
                  <span>En attente</span>
                </button>
              </div>
              <div className='bt-page-filter'>
                <button className='kbn-chip' type='button'>
                  <span>En transit</span>
                </button>
              </div>
              <div className='bt-page-filter'>
                <button className='kbn-chip' type='button'>
                  <span>En attente de confirmation</span>
                </button>
              </div>
              <div className='bt-page-filter'>
                <button className='kbn-chip' type='button'>
                  <span>Confirmée</span>
                </button>
              </div>
            </Slider>
          </div>
          <div className='bt-page-grid'>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
            <div className='bt-page-item'>
              <CommandCarde/>
            </div>
          </div>

          <button className='bt-home-products__more bt-home-products-more' type='button' onClick={() =>{}}>
            <div className='bt-home-products-more-line'></div>
            <span>Afficher plus</span>
            <div className='bt-home-products-more-line'></div>
          </button>
        </div>
      </div>
    </MainLayout>
  )
}
