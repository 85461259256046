import React, { useContext, useEffect } from 'react'
import { MainLayout } from '../../../../layout'
import { Button } from '../../../../components/src/button'
import { Link, useNavigate } from 'react-router-dom'
import { links } from '../../../../../routes'
import { UserAuthContext } from '../../../../../context/AuthContext'
import { capitalize } from '../../../../utils/functions'
import { format } from 'date-fns'

export const CommandCarde = () => {
  let navigate = useNavigate()
  return (
    <button type="button" className='bt-account-command' onClick={() => navigate(links.command + "command1")}>
      <div className='bt-account-command__top'>
        <h2>Commande N. 1234567890</h2>
        <p><span>8 articles</span> | <span>Total: </span><strong>125 000XOF</strong> - <span>Livraison: </span> <strong>10000XOF</strong></p>
        <p><span>Estimation de la date d'arrivée: </span> <strong>12/01/2025</strong></p>
      </div>
      <div className='bt-account-command__state'>
        <span>Etat: </span>
        <span className='kbn-tag is--info'>En transit</span>
      </div>
    </button>
  )
}

export const AccountPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.account}`)
    }
  }, [token, navigate]);
  
  return (
    <MainLayout title='Mon compte'>
      <div className='bt-page'>
          <div className='bt-account'>
            <div className='bt-account-me'>
              <div className='bt-account-me__avatar'>
                <i className="icon-circle-user"></i>
              </div>
              <div className='bt-account-me__container'>
                <h2>{info?.firstName + " " + info?.lastName?.toUpperCase()}</h2>
                <span>Compte créé le {info?.created_at ? capitalize(format(new Date(info?.created_at), "dd/MM/yyyy à HH:mm")) : "ND"}</span>
              </div>
              <div className='bt-account-me__actions'>
                <Button btnType={'button'} label={"Mes commandes"} btnStyle={'is--primary'} active={true} onClick={() => navigate(links.commands)}/>
                <button className='kbn-icon-btn is--neutral is--square_rounded' type='button' onClick={() => navigate(links.setting)}>
                  <i className=" icon-gear"></i>
                  <span className='bt-tooltip'>Paramatres du compte</span>
                </button>
              </div>
            </div>
            <div className='bt-account-container'>
              <div className='bt-account-title'>
                <h1>✋</h1>
                <strong>Salut! {info?.firstName + " " + info?.lastName?.toUpperCase()}</strong>
              </div>
              <div className='bt-account-grid'>
                <div className='bt-account-item is--special'>
                  <strong>Commande en cours</strong>
                  <div className='bt-account-item__orderS bt-account-item-orderS'>
                    <i className="icon-send-alt-2"></i>
                    <div className='bt-account-item-orderS__container'>
                      <div className='bt-account-item-orderS__top'>
                        <h2>Commande N. 1234567890</h2>
                        <p><span>8 articles</span> | <span>Total: </span><strong>125 000XOF</strong> - <span>Livraison: </span> <strong>10000XOF</strong></p>
                      </div>
                      <div className='bt-account-item-orderS__actions'>
                        <p><span>Estimation de la date d'arrivée: </span> <strong>12/01/2025</strong></p>
                        <Link to={links.command + "slug"} className='kbn-link'>
                          <span>Voir la commande</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='bt-account-item'>
                  <strong>Resumé de mes commandes</strong>
                  <div className='bt-account-item__container'>
                    <div className='bt-account-item__content'>
                      <span>Nombre total de commandes effectuée: </span>
                      <strong>100</strong>
                    </div>
                    <div className='bt-account-item__content'>
                      <span>Nombre total de commandes effectuée ce mois: </span>
                      <strong>2</strong>
                    </div>
                    <div className='bt-account-item__content'>
                      <span>Nombre total de commandes effectuée cette année: </span>
                      <strong>20</strong>
                    </div>
                  </div>
                </div>
                <div className='bt-account-item is--large'>
                  <strong>Mes 5 dernières commandes</strong>
                  <div className='bt-account-item__list'>
                    <CommandCarde/>
                    <CommandCarde/>
                    <CommandCarde/>
                    <CommandCarde/>
                    <CommandCarde/>
                  </div>
                  <button className='bt-home-products__more bt-home-products-more' type='button' onClick={() => navigate(links.commands)}>
                    <div className='bt-home-products-more-line'></div>
                    <span>Afficher plus</span>
                    <div className='bt-home-products-more-line'></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </MainLayout>
  )
}
