import React, { ChangeEvent, FormEvent, KeyboardEvent, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { titlePage } from '../../utils/functions'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { links } from '../../../routes'
import { Facebook, Instagram, Linkedin, LogoIcon, MeditationCuate, Twitter, Youtube } from '../../assets/illustration'
import { Textfield } from '../../components/src/field'
import { Button } from '../../components/src/button'
import { UserAuthContext } from '../../../context/AuthContext'
import { ThemeContext, devises, langues } from '../../../context/ThemeContext'
import { useAppDispatch, useAppSelector } from '../../../app/store'
import { getCategories, reset } from '../../../app/feature/category/category.slice'

export const MainLayout = ({here, title, children}:{here?:string, title?: string, children?: ReactNode}) => {
  titlePage(title)
  let navigate = useNavigate()
  const [search, setSearch] = useState<string>("")
  const [categorySelected, setCategorySelected] = useState<any>(false)
  const [openConfigs, setOpenConfigs] = useState<boolean>(false)
  const [openConfigs1, setOpenConfigs1] = useState<boolean>(false)
  const [openConfigs2, setOpenConfigs2] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [data, setData] = useState<any>({
    email: ""
  })
  const { token, info, logOut, isLoading } = useContext(UserAuthContext); 
 
  const dispatch = useAppDispatch();
  const {isLoading: isCLoading, categories, isError, message}: any = useAppSelector((state) => state.category);

  const navItems = [
    {
      link: links.home,
      label: "Accueil",
      here: here === "home" ? "active" : ""
    },
    // {
    //   link: links.services,
    //   label: "Services",
    //   here: here === "service" ? "active" : ""
    // },
    {
      link: links.about,
      label: "A propos",
      here: here === "about" ? "active" : ""
    },
    // {
    //   link: links.blogs,
    //   label: "Blog",
    //   here: here === "blog" ? "active" : ""
    // },
    // {
    //   link: links.gallery,
    //   label: "Galérie",
    //   here: here === "gallery" ? "active" : ""
    // },
  ]
  const {
    langue,
    setLangue,
    devise, setDevise,
  } = useContext(ThemeContext);

  const modalRef = useRef<any>(null);
  const modalRef1 = useRef<any>(null);
  const modalRef2 = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenConfigs(false);
      }
    };

    if (openConfigs) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openConfigs])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef1.current && !modalRef1.current.contains(event.target)) {
        setOpenConfigs1(false);
      }
    };

    if (openConfigs1) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openConfigs1])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef2.current && !modalRef2.current.contains(event.target)) {
        setOpenConfigs2(false);
      }
    };

    if (openConfigs2) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openConfigs2])

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if(search) navigate(links.product_search + `?wordkeys=${search}&type=all`)
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
  }
  
  useEffect(() => {
    dispatch(getCategories({
      page: 0,
      size: 500,
    }))
    return () => {
      dispatch(reset())
    }
  }, [dispatch])
  
  if(isLoading || isCLoading){
    return (
      <div className={"bt-loader"}>
        <i className="icon-spin"/>
      </div>
    )
  }

  if(isError){
    return (
      <div className={"kbn-user-error"}>
          <div className={"kbn-user-error__container"}>
            <i className="icon-triangle-exclamation"/>
            <h2>Oupss!! Une erreur est survenue</h2>
            <p>{message}</p>
            <div className={"kbn-user-error__action"}>
              <Button btnType={"button"} label={"Reactualiser"} btnStyle={"is--primary"} active={true} onClick={() => window?.location?.reload()}/>
            </div>
          </div>
      </div>
    )
  }

  return (
    <>
      <div className='bt-header'>
        <header className='bt-header-top'>
          <div className='bt-header-top__container'>
            <div className='bt-header-top__navigation bt-header-top-navigation'>
              <div className='bt-header-top-navigation__logo'>
                <Link to={links.home}>
                  <LogoIcon/>
                </Link>
              </div>
              <ul className='bt-header-top-navigation__list'>
                {
                  navItems.map((item: any, index: number) => (
                    <li className="bt-header-top-navigation__item" key={index}>
                      <NavLink to={item.link} className={item.here} end>
                        <span>{item.label}</span>
                      </NavLink>
                    </li>
                  ))
                }
              </ul>
            </div>
            <div className='bt-header-top__search bt-header-top-search'>
                <i className="icon-search-alt"></i>
                <input 
                  type="search" 
                  placeholder='Mots clés (appuyer sur Entrée pour effectuer la recherche)' 
                  value={search} 
                  onChange={handleChange} 
                  onKeyDown={handleKeyDown}  />
            </div>
            <div className='bt-header-top__auth bt-header-top-auth'>
              
              <div className='bt-header-top-auth__container'>
                <div className='bt-header-top-auth__group'>
                  <button type='button' className={`bt-header-top-auth__btn ${openConfigs1 ? "active" : ""}`} onClick={() => setOpenConfigs1(!openConfigs1)}>
                    <i className="icon-globe-alt"></i>
                    <span  className='bt-tooltip'>Active Language: {langues.filter((el: any) => el.value === langue)[0]?.label}</span>
                  </button>

                  <div className={`bt-header-top-auth-configs ${openConfigs1 ? "is--open" : ""}`} ref={modalRef1}>
                    {
                      langues.map((item, index) => (
                        <button type='button' className={`bt-header-top-auth-config ${item.value} ${langue === item.value ? "active" : ""}`} key={index} onClick={() => setLangue(item.value)}>
                          <span>{item.label}</span>
                        </button>
                      ))
                    }
                  </div>
                </div>

                <div className='bt-header-top-auth__group'>
                  <button type='button' className={`bt-header-top-auth__btn ${openConfigs2 ? "active" : ""}`} onClick={() => setOpenConfigs2(!openConfigs2)}>
                    <strong><i className="icon-credit-card"></i></strong>
                    <span className='bt-tooltip'>Active Currency: {devises.filter((el: any) => el.value === devise)[0]?.label + " ("+devises.filter((el: any) => el.value === devise)[0]?.symbol+")"}</span>
                  </button>

                  <div className={`bt-header-top-auth-configs ${openConfigs2 ? "is--open" : ""}`} ref={modalRef2}>
                  {
                    devises.map((item, index) => (
                      <button type='button' className={`bt-header-top-auth-config  ${item.value} ${devise === item.value ? "active" : ""}`} key={index} onClick={() => setDevise(item.value)}>
                          <span>{item.label}</span>
                      </button>
                    ))
                  }
                </div>
                </div>

                {
                  token ? (
                    <>
                      <button type='button' className={`bt-header-top-auth__btn ${here === "notif" ? "active" : ""}`} onClick={() => navigate(links.notification)}>
                        <i className="icon-bell-alt"></i>
                        <span className='bt-tooltip'>Notification</span>
                        <span className='notice'></span>
                      </button>
                      <button type='button' className={`bt-header-top-auth__btn ${here === "cart" ? "active" : ""}`} onClick={() => navigate(links.cart)}>
                        <i className="icon-bag-shopping"></i>
                        <span className='bt-tooltip'>My cart</span>
                      </button>
                    </>
                  ) : null
                }
                <div className='bt-header-top-auth__group'>

                  <button type='button' className={`bt-header-top-auth__btn ${here === "account" ? "active" : ""}`} onClick={() => setOpenConfigs(!openConfigs)}>
                    {
                      info?.avatar ? (
                        <img src={info?.avatar } alt="avatar" />
                      ) : (
                        <i className="icon-user"></i>
                      )
                    }
                    
                    {
                      token ? (
                        <span className='bt-tooltip'>My account</span>
                      ) : (
                        <span className='bt-tooltip'>Se connecter ou S'inscrire</span>
                      )
                    }
                  </button>
                  <div className={`bt-header-top-auth-configs ${openConfigs ? "is--open" : ""}`} ref={modalRef}>
                    {
                      token ? (
                        <>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.account)}>
                            <span>Mon compte</span>
                          </button>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.commands)}>
                            <span>Mes commandes</span>
                          </button>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.setting)}>
                            <span>Paramètres</span>
                          </button>
                          <button type='button' className='bt-header-top-auth-config danger' onClick={() => logOut()}>
                            <span>Se deconnecter</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.login)}>
                            <span>Se connecter</span>
                          </button>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.register)}>
                            <span>Créer un compte</span>
                          </button>
                          <button type='button' className='bt-header-top-auth-config' onClick={() => navigate(links.setting_other)}>
                            <span>Paramètres</span>
                          </button>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className='bt-header-bottom'>
          <div className='bt-header-bottom__submenu bt-header-bottom-submenu'>
            <button type='button' className='bt-header-bottom-submenu__btn' onClick={() => {setOpenMenu(!openMenu); setCategorySelected(null)}}>
              <i className={!openMenu ? "icon-list-ul-alt" : "icon-xmark"}></i>
              <span>{!openMenu ? "Toutes les categories" : "Fermer"}</span>
            </button>
            <div className={`bt-header-bottom-submenu__container ${openMenu ? "is--open" : ""}`}>
              
              <div className='bt-header-bottom-submenu__categories'>
                <h3>Toutes les categories</h3>
                {
                  categories.filter((el: any) => el.parent === null).map((item: any, index: number) => (
                    <button key={index} className={`bt-header-bottom-submenu__category ${categorySelected?.id === item.id? "is--active" : ""}`} type='button' onClick={() => setCategorySelected(item)}>
                      <span>{item.label}</span>
                    </button>
                  ))
                }
              </div>
              <div className='bt-header-bottom-submenu__subcategories bt-header-bottom-submenu-subcategories'>
                {
                  categorySelected ? (
                    <>
                      <div className='bt-header-bottom-submenu-subcategories__header'>
                        <h3>{categorySelected?.label}</h3>
                        <button type='button' onClick={() => {navigate(links.product_search + `?wordkeys=${categorySelected?.label}&type=category`); setOpenMenu(false)}} className='kbn-link'>
                          <span>Voir plus</span> <i className="icon-arrow-narrow-right"></i>
                        </button>
                      </div>
                      {
                        categories?.filter((el: any) => el.parent?.id === categorySelected?.id).length > 0 ? (
                          <div className='bt-header-bottom-submenu-subcategories__list'>
                            {
                              categories?.filter((el: any) => el.parent?.id === categorySelected?.id).map((item: any, index: number) => (
                                <button key={index} className={`bt-header-bottom-submenu-subcategories__item`} type='button' onClick={() => {navigate(links.product_search + `?wordkeys=${item?.label}&type=subcategory`);  setOpenMenu(false)}}>
                                  <span>{item.label}</span>
                                </button>
                              ))
                            }
                          </div>
                        ) : (
                          <div className='bt-header-bottom-submenu-subcategories__empty'>
                            <MeditationCuate/>
                            <span>Aucune usous-catégorie...</span>
                          </div>
                        )
                      }
                    </>
                  ) : (
                    <div className='bt-header-bottom-submenu-subcategories__empty'>
                      <MeditationCuate/>
                      <span>Veuillez choisir une catégorie...</span>
                    </div>
                  )
                }

              </div>
            </div>
          </div>
          <ul className='bt-header-bottom__navigation bt-header-bottom-navigation'>
            {
              categories?.filter((el: any) => el.parent === null)?.map((item: any, index: number) => (
                <li className="bt-header-bottom-navigation__item" key={index}>
                  <NavLink to={links.product_search + `?wordkeys=${item?.label}&type=subcategory`}>
                    <span>{item.label}</span>
                  </NavLink>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      <main className='bt-main' role='main'>
        {children}
      </main>
      <footer className='bt-footer'>
        <div className='bt-footer__container'>
          <div className='bt-footer__logo'>
            <LogoIcon/>
          </div>
          <div className='bt-footer__boxes'>
            <div className='bt-footer__box'>
              <h2>Berong Trade</h2>
              <ul className='bt-footer__list'>
                <li className="bt-footer__item">
                  <Link to={links.about}>
                    <span>A propos de nous</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.services}>
                    <span>Nos offres et Services</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.about}>
                    <span>Nos Partenaires</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.gallery}>
                    <span>Galerie</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.blogs}>
                    <span>Blogs</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.about}>
                    <span>Contacts</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className='bt-footer__box'>
              <h2>Besoins d’aide</h2>
              <ul className='bt-footer__list'>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=guide-achat"}>
                    <span>Guide d’achats</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=buying"}>
                    <span>Paiement</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=delivering"}>
                    <span>Livraison</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=remboursement"}>
                    <span>Retours et remboursement</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=assistance"}>
                    <span>Aide et Assistance</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.faq+"?goto=support-clientele"}>
                    <span>Supports clientèle </span>
                  </Link>
                </li>
                
              </ul>
            </div>
            <div className='bt-footer__box'>
              <h2>Lois et règles </h2>
              <ul className='bt-footer__list'>
                <li className="bt-footer__item">
                  <Link to={links.terms+"#condition-utilisation"}>
                    <span>Conditions générales d'utilisation</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.terms+"#condition-reprise"}>
                    <span>Conditions Générales du Service de Reprise</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.terms+"#contrat-commercial"}>
                    <span>Contrat de garantie commerciale</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.terms+"#protection-donnee"}>
                    <span>Protection des données</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.terms+"#cookies"}>
                    <span>Cookies et paramètres de confidentialité</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.terms}>
                    <span>Autres infos légales</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <Link to={links.mention}>
                    <span>Mentions légales</span>
                  </Link>
                </li>
                <li className="bt-footer__item">
                  <span>Achats 100% sécurisés</span>
                </li>
                
              </ul>
            </div>
            <div className='bt-footer__box'>
              <h2>S’inscrire à la newsletter</h2>
              <form className='bt-footer__form' onSubmit={handleSubmit}>
                <div className='bt-footer__field'>
                  <Textfield
                    id={"email"}
                    value={data.email}
                    require={true}
                    changeValue={(val: string) => setData({...data, email: val})}
                    type={"text"}
                    label={"Email"}
                    placeholder={"Veuillez insérer votre email"}
                  />
                </div>
                <div className='bt-footer__action'>
                  <Button btnType={"submit"} label={"S’inscrire"} btnStyle={"is--primary"} active={true} withIconLeft={false} iconLeftClass={"icon-spin"}/>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='bt-footer__bottom'>
          <p className='bt-footer__copyright'>© 2024 Berong Trade</p>
          <div className='bt-footer__socials bt-footer-socials'>
            <span>Rejoignez-nous sur</span>
            <ul className='bt-footer-socials__list'>
              <li className="bt-footer-social">
                <a href="google.com" target='_blank' rel="norefferer">
                  <Linkedin/>
                </a>
              </li>
              <li className="bt-footer-social">
                <a href="google.com" target='_blank' rel="norefferer">
                  <Facebook/>
                </a>
              </li>
              <li className="bt-footer-social">
                <a href="google.com" target='_blank' rel="norefferer">
                  <Twitter/>
                </a>
              </li>
              <li className="bt-footer-social">
                <a href="google.com" target='_blank' rel="norefferer">
                  <Instagram/>
                </a>
              </li>
              <li className="bt-footer-social">
                <a href="google.com" target='_blank' rel="norefferer">
                  <Youtube/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  )
}
