
import {differenceInCalendarYears, parseISO, subDays, subWeeks, subYears} from "date-fns";
import {format} from "date-fns/format"

import {appName} from "./constant";


export const capitalize = (word: string) => {
    const lower = word.toLowerCase()
    return lower.charAt(0).toUpperCase() + lower.slice(1)
}

export const typeDays = [
    {
        name: "Jours",
        value: "jours"
    },
    {
        name: "Semaines",
        value: "semaines"
    },
    {
        name: "Années",
        value: "annees"
    },
]


export const titlePage = (title?: string) => {
    if(title){
        document.title = title + " | " + appName;
    }else {
        document.title =" Bienvenue sur  Berong Trade";
    }
}

export function truncate(str: string | string[], maxlength: number) {
    return (str.length > maxlength) ?
        str.slice(0, maxlength - 1) + '…' : str;
}

export const formatNumber = (data: number) => {
    let number = data

    let result = number / 1000000000

    if(result >= 1){
        return Math.round(result*100)/100 + "Md"
    }
    result = number / 1000000
    if(result >= 1){
        return Math.round(result*100)/100 + "M"
    }
    result = number / 1000
    if(result >= 1){
        return result + "k"
    }
    return number
}

export const formatNumber2 = (data: number) => {
    let number = data

    let result = number / 1000000000
    if(result >= 1){
        return Math.round(result*100)/100 + "Md"
    }
    result = number / 1000000
    if(result >= 1){
        return Math.round(result*100)/100 + "M"
    }
    return number
}
export const formatMyDate = (mdate: any) => {
    let now = Date.now()
    let day = new Date(mdate)
    // @ts-ignore
    var dif = now - day
    let jour = dif / (1000 * 3600 * 24)

    if(jour > 1){
        return "il y a " + Math.round(jour) + " jrs"
    }else{
        if(jour === 1){
            return " hier"
        }else{
            let heure = dif / (1000 * 3600)
            if(heure > 1){
                return "il y a " + Math.round(heure) + " h"
            }else{
                let min = dif / (1000 * 60)
                if(min > 1){
                    return "il y a " + Math.round(min)+ " min"
                }else{
                    let second = dif / 1000
                    if(second > 1){
                        return "il y a " + Math.round(second)+"s"
                    }else{
                        return "maintenant"
                    }
                }
            }
        }
    }
}

export const fileSizeCalculate = (size: number) => {
    let sizeInBytes = size / 1000000
    if(sizeInBytes > 1){
        return Math.round(sizeInBytes) + "Mo"
    }else{
        return Math.round(sizeInBytes * 10) / 10 + "ko"
    }
}
export const quelDateCest = (type: string, nombre: number) => {
    let today: any = new Date()
    let newDate: any

    switch (type){
        case "jours":
            newDate = subDays(today, nombre)
            return format(new Date(newDate), "yyyy-MM-dd")

        case "semaines":
            newDate = subWeeks(today, nombre)
            return format(new Date(newDate), "yyyy-MM-dd")

        case "annees":
            newDate = subYears(today, nombre)
            return format(newDate, "yyyy-MM-dd")

        default:
            return format(new Date(), "yyyy-MM-dd")
    }
}

export const fetchAgenda = (rdvs: any[]) => {
    let tabs: any[] = []

    rdvs.forEach((rdv) => {
        
        tabs = [...tabs, {
            slug: rdv.slug,
            type: rdv.type_rendez_vous,
            user: rdv.patient,
            title: `${rdv.titre} .${rdv.type_rendez_vous} - Patient: ${rdv?.patient?.auth?.prenom + " " +rdv?.patient?.auth?.nom?.toUpperCase()}`,
            start: parseISO(`${rdv.date?.split("T")[0]}T${rdv.heure_debut}:00`),
            end: parseISO(`${rdv.date?.split("T")[0]}T${rdv.heure_fin}:00`)
        }]
    })
    return tabs
}

export function calculateAge(dob: any) {
    return differenceInCalendarYears(new Date(), new Date(dob))
}

export function createInitial(name: string) {
    // Diviser le nom en mots séparés par des espaces
    const words = name.split(' ');

    // Créer une variable pour stocker l'initial
    let initial = '';

    // Parcourir chaque mot
    for (let i = 0; i < words.length; i++) {
        // Ajouter la première lettre de chaque mot à l'initial
        initial += words[i][0];
    }

    // Renvoyer l'initial en majuscules
    return initial.toUpperCase();
}

export function randomIntFromInterval(min: number, max: number) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export const verifyAccess = (roles: any[], model: string) => {
    let role: any = roles?.filter((el) => el?.models?.filter((ele: any) => ele === model).length > 0)
    if(role && role?.length > 0) {
        return true
    }
    return false
}

export const verifyPrivilige = (roles: any[], model: string, action: string) => {
    let role: any = roles?.filter((el) => el?.models?.filter((ele: any) => ele === model).length > 0)
                            .filter((elem) => elem?.actions?.filter((ele: any) => ele === action).length > 0)
    if(role && role?.length > 0) {
        return true
    }
    return false
}


export const mixImageVideos = (images: string[], videos: string[]) => {
    let tabs: any[] = []

    videos?.forEach((el) => {
        tabs = [...tabs, {
            type: "video",
            url: el
        }]
    })

    images?.forEach((el) => {
        tabs = [...tabs, {
            type: "image",
            url: el
        }]
    })

    return tabs
}
export const mixImageVideos2 = (images: string[], videos: string[]) => {
    let tabs: any[] = []

    videos?.forEach((el) => {
        tabs = [...tabs, {
            original: el,
            thumbnail: el,
            
            // thumb: el
        }]
    })

    images?.forEach((el) => {
        tabs = [...tabs, {
            original: el,
            thumbnail: el,
        }]
    })

    return tabs
}

export function roundToDecimal(num: any, decimals: any) {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
}

export const convertPrice = (price: any, devise: string) => {
    if(devise === "xof"){
        return roundToDecimal(parseFloat(price) * 83.32, 2)
    }
    if(devise === "euro"){
        return roundToDecimal(parseFloat(price) * 0.13, 2)
    }
    if(devise === "dollar-us"){
        return roundToDecimal(parseFloat(price) * 0.14, 2)
    }
    return price
}



export const formatPrice = (price: any, currency: string) => {
    let mcurrency = "CNY"
    let mlag = "zh-CN"

    if(currency === "xof"){
        mcurrency = "XOF"
        mlag = "fr-FR"
    }
    if(currency === "euro"){
        mcurrency = "EUR"
        mlag = "fr-FR"
    }
    if(currency === "dollar-us"){
        mcurrency = "USD"
        mlag = "en-US"
    }
    if(currency === "yuan"){
        mcurrency = "CNY"
        mlag = "zh-CN"
    }

    return new Intl.NumberFormat(mlag, { style: 'currency', currency: mcurrency }).format(price);

}
