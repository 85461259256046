import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { APIURL, getUnProtecteItems } from '../../utils';
import { CategoryState } from '../../interface/GeneralStateInterface';

const URI = APIURL+"/product/category"

const initialState: CategoryState = {
    categories: [],
    category: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    message: null,
    categories_info: null
}

export const getCategories = createAsyncThunk<any, Object>(
    'category/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}/list?pageNumber=${content?.page}&limit=${content?.size}`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getCategory = createAsyncThunk<any, Object>(
    'category/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}?id=${content}'`,
                token: tokens
            }
            return await getUnProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)



export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false


            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
        
        .addCase(getCategories.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCategories.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.categories_info = action.payload
            state.categories = action.payload?.content
        })
        .addCase(getCategories.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getCategory.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getCategory.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.category = action.payload
        })
        .addCase(getCategory.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.category = null
        })
    },
})

export const { reset } = categorySlice.actions
export default categorySlice.reducer