import React, { FC, ReactNode } from 'react'
import { LogoIcon } from '../../assets/illustration'
import images from '../../assets/images'
import { links } from '../../../routes'
import { titlePage } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'

interface AuthLayoutParams {
  title?: string, 
  subtitle?: string, 
  withBack?: boolean, 
  image?: string, 
  children?: ReactNode
}

export const AuthLayout: FC<AuthLayoutParams> = ({title = "Connexion", subtitle = "Accès reservé", withBack = false, image, children}) => {
  titlePage(title)
  const {image1} = images
  let navigate = useNavigate()
  return (
    <div className='kbn-l-auth'>
      <div className='kbn-l-auth-content'>
        <div className='kbn-l-auth-header'>
          {
            withBack ? (
              <div className='kbn-l-auth-header-back'>
                <button type='button'  onClick={() => navigate(links.home)}>
                  <i className="kbn-icon-home"></i>
                  <span>Revenir à l'accueil</span>
                </button>
              </div>
            ) : null
          }
         
          <div className='kbn-l-auth-logo'>
            <LogoIcon/>
          </div>
          <div className='kbn-l-auth-header__container'>
            <h1>{title}</h1>
            <span>{subtitle}</span>
          </div>
        </div>
        <div className='kbn-l-auth-container'>
          {children}
        </div>
        <div className='kbn-l-auth-footer'>
          <p>©2024 Berong Trade</p>
        </div>
      </div>
      <div className='kbn-l-auth-image'>
        <img src={image || image1} alt="auth" />
      </div>
    </div>
  )
}
